import React, { useState, useMemo, useContext, useEffect } from "react";
import OrderService from "../../../services/OrderService";
import { UserContext } from "../../context/UserContext";
import OrderCard from "./components/OrderCard";
import OrderModal from "./components/OrderModal";
import './assets/scss/main.scss';
import { useSocket } from "../../context/SocketContext";
import Notification from "../../components/Notification/Notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faFaceFrown, faFilter, faObjectGroup, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import renameKeys from "./utils/renameKeys";
import OrderGroupingService from "../../../services/OrderGroupingService";
import Swal from "sweetalert2";
import OrderFilters from "./components/OrderFilters";
import { Oval } from "react-loader-spinner";

const OrderManagement = (props) => {
    const { selectedStore } = useContext(UserContext);
    const orderService = useMemo(() => new OrderService(), []);
    const orderGroupingService = useMemo(() => new OrderGroupingService(), []);

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        startDate: new Date().toISOString().slice(0, 10),
        endDate: new Date().toISOString().slice(0, 10),
        deliveryCode: "",
        customerName: "",
        page: 1,
        status: "pending"
    });
    const [showFilters, setShowFilters] = useState(false);
    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 0,
        totalOrders: 0,
        totalPages: 1,
    })
    const [groupOrders, setGroupOrders] = useState([]);
    const [showGroupCheck, setShowGroupCheck] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [actualList, setActualList] = useState(null);
    const [unconfirmedOrders, setUnconfirmedOrders] = useState(0);
    const [pendingOrders, setPendingOrders] = useState(0);
    const [acceptOrders, setAcceptOrders] = useState(0);
    const [arrivedOrders, setArrivedOrders] = useState(0);
    const [withdrawnOrders, setWithdrawnOrders] = useState(0);
    const [deliveredOrders, setDeliveredOrders] = useState(0);
    const [canceledOrders, setCanceledOrders] = useState(0);

    function handleGroupOrder(order, isChecked) {
        if (isChecked) setGroupOrders((prevOrders) => [...prevOrders, order]);
        else setGroupOrders((prevOrders) => prevOrders.filter((o) => o._id !== order._id));
    }

    function startGrouping() {
        setShowGroupCheck(true);
    }

    function stopGrouping() {
        setGroupOrders([]);
        setShowGroupCheck(false);
    }

    async function createOrdersGrouping() {
        setLoading(true);
        props.setSpinner(true);
        setTimeout(async () => {
            try {
                const store = renameKeys(selectedStore);
                const response = await orderGroupingService.createOrderGrouping(store, groupOrders);
                props.setSpinner(false);
                props.setTextSpinner('');
                if (response) {
                    Swal.fire({
                        title: 'Entregas agrupadas com sucesso!',
                        icon: 'success',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                } else {
                    Swal.fire({
                        title: 'Ocorreu um erro ao agrupar as entregas!',
                        icon: 'error',
                        timer: 1500,
                        showConfirmButton: false,
                    })
                }
                setLoading(false);
                stopGrouping();
                loadOrders(false);
            } catch (error) {
                stopGrouping();
                Swal.fire({
                    title: 'Ocorreu um erro ao agrupar as entregas!',
                    text: error,
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                })
            }
        }, 2000);
    }

    const handleShowFilters = () => {
        setShowFilters((prevState) => !prevState);
    }

    const previousPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page: filters.page - 1
        }));
    }

    const nextPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page: filters.page + 1
        }));
    }

    const handleFilters = (query) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            startDate: query.startDate,
            endDate: query.endDate,
            deliveryCode: query.deliveryCode,
            customerName: query.customerName,
        }));
    };

    function handleChangeOrders(status) {
        setFilters((prevFilters) => ({
            ...prevFilters,
            status: status
        }));
    }

    function handleShowModal(order) {
        setSelectedOrder(order);
        setIsModalOpen(order !== null);
    }

    async function acceptOrder(isIFood = false, isAnotaAi = false, orderId, storeId) {
        props.setSpinner(true);
        await orderService.confirmOrder(orderId, storeId, isIFood, isAnotaAi);
        setTimeout(() => {
            props.setSpinner(false);
            setLoading(false);
        }, 1000);
    }

    async function getCountByStatus() {
        const response = await orderService.getCountStatusByStore(selectedStore._id);
        setUnconfirmedOrders(response.unconfirmed);
        setPendingOrders(response.pending);
        setAcceptOrders(response.accept);
        setArrivedOrders(response.arrived);
        setWithdrawnOrders(response.withdrawn);
        setDeliveredOrders(response.delivered);
        setCanceledOrders(response.canceled);
    }

    async function loadOrders(status) {
        if (loading) {
            return;
        }
        setOrders([]);
        setLoading(true);
        const response = await orderService.getAllOrdersByStore(selectedStore._id, filters);
        setTimeout(() => {
            setActualList(status);
            setPagination(response.pagination);
            setOrders(response.data);
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {
        loadOrders(filters.status);
        // eslint-disable-next-line
    }, [filters]);


    useEffect(() => {
        getCountByStatus();
        loadOrders('pending');
        document.title = `Acompanhamento | Motum`;
        // eslint-disable-next-line
    }, [selectedStore]);

    // Notificações
    const [orderQueue, setOrderQueue] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [notificationData, setNotificationData] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const socket = useSocket();

    const schedulePushNotification = (data) => {
        setNotificationData(data);
        setShowNotification(true);
        loadOrders(filters);
        setLoading(false);
        setTimeout(() => {
            setShowNotification(false);
            setOrderQueue((prevQueue) => prevQueue.slice(1));
        }, 2000);
        setProcessing(false);
    }

    useEffect(() => {
        const handleUpdateOrder = (data) => {
            setOrderQueue((prevQueue) => [...prevQueue, data]);
        }

        socket.on('CANCEL_ORDER', handleUpdateOrder);
        socket.on('DELIVERED_ORDER', handleUpdateOrder);
        socket.on('WITHDRAWN_ORDER', handleUpdateOrder);
        socket.on('ARRIVED_ORDER', handleUpdateOrder);
        socket.on('ACCEPT_ORDER', handleUpdateOrder);
        socket.on('LOADING', () => loadOrders(false));

        return () => {
            socket.off('CANCEL_ORDER', handleUpdateOrder);
            socket.off('DELIVERED_ORDER', handleUpdateOrder);
            socket.off('WITHDRAWN_ORDER', handleUpdateOrder);
            socket.off('ARRIVED_ORDER', handleUpdateOrder);
            socket.off('ACCEPT_ORDER', handleUpdateOrder);
            socket.off('LOADING', () => loadOrders(false));
        };
        //eslint-disable-next-line
    }, [socket]);

    useEffect(() => {
        if (orderQueue.length > 0 && !processing && !showNotification) {
            setProcessing(true);
            const orderToProcess = orderQueue[0];
            schedulePushNotification(orderToProcess);
        }
        // eslint-disable-next-line
    }, [orderQueue, processing, showNotification]);

    return (
        <div className="content-container order-management-container">
            <div className="filter-buttons-container">
                <div className="filter-box" style={{ backgroundColor: '#D4D4D4', borderColor: '#8A8F9B', borderWidth: 2 }} onClick={() => handleChangeOrders("unconfirmed")}>
                    <div className="filter-box-count">{unconfirmedOrders}</div>
                    <div className="filter-box-text">Pendentes</div>
                </div>
                <div className="filter-box" style={{ backgroundColor: '#D4D4D4', borderColor: '#8A8F9B', borderWidth: 2 }} onClick={() => handleChangeOrders("pending")}>
                    <div className="filter-box-count">{pendingOrders}</div>
                    <div className="filter-box-text">Aguardando entregador</div>
                </div>
                <div className="filter-box" style={{ backgroundColor: '#D56631' }} onClick={() => handleChangeOrders("accept")}>
                    <div className="filter-box-count">{acceptOrders}</div>
                    <div className="filter-box-text">Entrega aceita</div>
                </div>
                <div className="filter-box" style={{ backgroundColor: '#DEB543' }} onClick={() => handleChangeOrders("arrived")}>
                    <div className="filter-box-count">{arrivedOrders}</div>
                    <div className="filter-box-text">Entregador chegou</div>
                </div>
                <div className="filter-box" style={{ backgroundColor: '#FFF' }} onClick={() => handleChangeOrders("withdrawn")}>
                    <div className="filter-box-count">{withdrawnOrders}</div>
                    <div className="filter-box-text">Saiu para entrega</div>
                </div>
                <div className="filter-box" style={{ backgroundColor: '#8EDDA6' }} onClick={() => handleChangeOrders("delivered")}>
                    <div className="filter-box-count">{deliveredOrders}</div>
                    <div className="filter-box-text">Concluídos</div>
                </div>
                <div className="filter-box" style={{ backgroundColor: '#C47D7B' }} onClick={() => handleChangeOrders("canceled")}>
                    <div className="filter-box-count">{canceledOrders}</div>
                    <div className="filter-box-text">Canceladas</div>
                </div>
            </div>
            <div className="actions-buttons">
                <button type="button" className="show-filters" onClick={handleShowFilters}><FontAwesomeIcon icon={faFilter} style={{ height: 15 }} /></button>
                {showFilters && <OrderFilters onApplyFilters={handleFilters} />}
                {(actualList === "pending" && orders.length > 0) &&
                    <div className="group-buttons">
                        {!showGroupCheck && <button type="button" className="btn-primary" onClick={startGrouping}><FontAwesomeIcon icon={faObjectGroup} title="Agrupamento" style={{ marginRight: 10, height: 15, color: '#fff' }} />Agrupar Pedidos</button>}
                        {showGroupCheck && <button type="button" className="btn-primary" onClick={createOrdersGrouping}><FontAwesomeIcon icon={faPlus} title="Agrupamento" style={{ marginRight: 10, height: 15, color: '#fff' }} />Criar Agrupamento</button>}
                        {showGroupCheck && <button type="button" className="btn-danger" onClick={stopGrouping}><FontAwesomeIcon icon={faXmark} title="Agrupamento" style={{ marginRight: 10, height: 15, color: '#fff' }} />Cancelar</button>}
                    </div>
                }
            </div>
            <div className="management-list">
                {orders.length > 0 ?
                    orders.map((order) => (<OrderCard key={order._id} order={order} acceptOrder={acceptOrder} handleShowModal={handleShowModal} handleGroupOrder={handleGroupOrder} showGroupCheck={showGroupCheck} />))
                    :
                    (
                        <div className="no-orders">
                            {loading ? (
                                <Oval
                                    visible={loading}
                                    height="50"
                                    width="50"
                                    color="#0d6efd"
                                    secondaryColor="#0d6efd"
                                    radius="1"
                                />
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faFaceFrown} />
                                    <p>Que pena!</p>
                                    <p>Parece que você ainda tem pedidos no momento!</p>
                                </>
                            )}
                        </div>
                    )
                }
            </div>
            <div className="pagination-container">
                {filters.page > 1 && <button type="button" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /></button>}
                {pagination.totalPages > 1 && <span>{filters.page}</span>}
                {filters.page < pagination.totalPages && <button type="button" onClick={nextPage}><FontAwesomeIcon icon={faChevronRight} /></button>}
            </div>
            {isModalOpen && <OrderModal order={selectedOrder} setSpinner={props.setSpinner} handleShowModal={handleShowModal} />}
            {showNotification && <Notification notificationData={notificationData} />}
        </div>
    );
}

export default OrderManagement;