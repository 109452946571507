import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPix } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faClock, faEye, faEyeSlash, faWallet, faWindowClose, faBarcode, faCreditCard, faLandmark } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../context/UserContext';
import WalletService from '../../../services/WalletService';
import Conversor from '../../../helper/Conversor';
import Swal from 'sweetalert2';
import Inputmask from 'inputmask';
import './style.scss';

const Wallet = (props) => {
  const [hideBalance, setHideBalance] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(null);
  const { user, selectedStore } = useContext(UserContext);
  const walletService = useMemo(() => new WalletService(), []);

  async function loadWallet() {
    if (loading) {
      return;
    }
    setLoading(true);
    props.setSpinner(true);
    setTimeout(async () => {
      const response = await walletService.loadWallet(user._id);
      setWallet(response.wallet);
      props.setSpinner(false);
    }, 2000);
  }

  function priceToBRLCurrency(valor = 0) {
    return Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(valor);
  }

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    Swal.update({
      confirmButtonText: 'Chave copiada!',
      confirmButtonColor: '#28a745'
    });
    setTimeout(() => {
      Swal.update({
        confirmButtonText: 'Copiar QR Code',
    confirmButtonColor: '#000',
      });
    }, 3000);
    return;
  }

  const handleAddBalance = async () => {
    const { value: depositAmount } = await Swal.fire({
      title: 'Digite o valor da transferência',
      html: `<input id="saldo-input" type="text" class="swal2-input" placeholder="R$ 0,00" />`,
      showCancelButton: true,
      confirmButtonText: 'Gerar Pix',
      confirmButtonColor: '#000',
      cancelButtonText: 'Cancelar',
      didOpen: () => {
        const input = document.getElementById('saldo-input');
        Inputmask({
          alias: 'numeric',
          groupSeparator: '.',
          radixPoint: ',',
          prefix: 'R$ ',
          autoGroup: true,
          digits: 2,
          digitsOptional: false,
          placeholder: '0',
          clearMaskOnLostFocus: false
        }).mask(input);
      },
      preConfirm: () => {
        const input = document.getElementById('saldo-input').value;
        const numericValue = parseFloat(input.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));

        if (isNaN(numericValue) || numericValue <= 0) {
          Swal.showValidationMessage('Por favor, insira um valor válido!');
          return null;
        }

        return numericValue;
      }
    });

    if (depositAmount) {
      try {
        props.setSpinner(true);
        const response = await walletService.newIntentDeposit(selectedStore._id, depositAmount);
        const pixData = response.data;
        props.setSpinner(false);
        Swal.fire({
          title: 'Use o QR Code do Pix abaixo',
          html: `
            <div class="pix-modal">
              <p class="pix-modal-value">Valor: ${priceToBRLCurrency(depositAmount)}</p>
              <p class="pix-modal-id">ID da transação<br> ${pixData.transactionId}</p>
              <div style="margin: 20px 0;" class="pix-modal-qr-code">
                <img src="data:image/png;base64,${pixData.qrCode}" alt="QR Code para pagamento" style="width: 200px; height: 200px; border: 1px solid #ccc; border-radius: 8px;" />
              </div>
              <p class="pix-modal-key-title">Pix Copia e Cola</p>
              <input class="pix-modal-key-input" type="text" value="${pixData.code}" readonly style="width: 100%; padding: 8px; border: 1px solid #ccc; border-radius: 4px;" />
            </div>
          `,
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: 'Fechar',
          confirmButtonText: 'Copiar QR Code',
          confirmButtonColor: '#000',
          allowOutsideClick: false,
          preConfirm: async () => {
            await copyTextToClipboard(pixData.code);
            return false;
          }
        });
      } catch (error) {
        props.setSpinner(false);
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro!',
          text: 'Tente novamente mais tarde.',
          showConfirmButton: false,
          timer: 2000
        });
      }
    }
  };


  useEffect(() => {
    document.title = `Carteira | Motum`;
  });

  useEffect(() => {
    loadWallet();
    // eslint-disable-next-line
  }, [loading]);

  const transactionMap = {
    "CREDIT": "CRÉDITO",
    "DEBIT": "DÉBITO",
    "PENDING": "PENDENTE",
    "APPROVED": "APROVADO",
    "CANCELLED": "CANCELADO"
  }

  const transactionIconMap = {
    "APPROVED": <FontAwesomeIcon icon={faCheckSquare} color="#168821ff" style={{ marginRight: 10 }} />,
    "PENDING": <FontAwesomeIcon icon={faClock} color="#ffcd07ff" style={{ marginRight: 10 }} />,
    "CANCELLED": <FontAwesomeIcon icon={faWindowClose} color="#e52207ff" style={{ marginRight: 10 }} />,
  };

  return (
    <React.Fragment>
      <div className="content-container">
        <div className="main-balance-container">
          <div className="balance-info-container">
            <div className="balance-info-header">
              <span>Conta</span>
              <FontAwesomeIcon icon={faWallet} />
            </div>
            <div className="balance-info-amount-container">
              <div className="balance-info-amount">
                <div className="amount-title">Saldo disponível</div>
                <div className="amount-value">{!hideBalance && wallet ? Conversor.converterStringReal(wallet.amount.value) : "***********"}</div>
              </div>
              <div className="hide-amount" onClick={() => setHideBalance(prevState => !prevState)}><FontAwesomeIcon icon={!hideBalance ? faEyeSlash : faEye} /></div>
            </div>
          </div>

          <div className="balance-actions-container">
            <div className="action-item" onClick={handleAddBalance}>
              <div className="action-icon"><FontAwesomeIcon icon={faPix} /></div>
              <div className="action-text">Depositar com Pix</div>
            </div>
            <div className="action-item" style={{ opacity: 0.5, cursor: 'not-allowed' }}>
              <div className="action-icon"><FontAwesomeIcon icon={faPix} /></div>
              <div className="action-text">Sacar com Pix</div>
            </div>
            <div className="action-item" style={{ opacity: 0.5, cursor: 'not-allowed' }}>
              <div className="action-icon"><FontAwesomeIcon icon={faBarcode} /></div>
              <div className="action-text">Boleto</div>
            </div>
            <div className="action-item" style={{ opacity: 0.5, cursor: 'not-allowed' }}>
              <div className="action-icon" ><FontAwesomeIcon icon={faCreditCard} /></div>
              <div className="action-text">Cartões</div>
            </div>
            <div className="action-item" style={{ opacity: 0.5, cursor: 'not-allowed' }}>
              <div className="action-icon"><FontAwesomeIcon icon={faLandmark} /></div>
              <div className="action-text">Extrato</div>
            </div>
          </div>
        </div>
        <div className="transactions-container">
          <div className="transactions-title">Histórico de transações</div>
          <div className="transactions-table">
            <table>
              <thead>
                <tr>
                  <th className="transaction-date">Data da transação</th>
                  <th className="transaction-type">Tipo</th>
                  <th className="transaction-value">Valor</th>
                  <th className="transaction-description">Descrição</th>
                  <th className="transaction-status">Status</th>
                </tr>
              </thead>
              <tbody>
                {wallet && wallet.transactions
                  .sort((a, b) => new Date(b.movementAt) - new Date(a.movementAt))
                  .map((transaction, index) => (
                    <tr key={index}>
                      <td className="transaction-date">{Conversor.converterData(transaction.movementAt)}</td>
                      <td className={`transaction-type${transaction.type === "CREDIT" ? "-credit" : "-debit"}`}>{transactionMap[transaction.type]}</td>
                      <td className={`transaction-value${transaction.type === "CREDIT" ? "-credit" : "-debit"}`}>{transaction.type === "CREDIT" ? "+ " : "- "}{Conversor.converterStringReal(transaction.amount.value)}</td>
                      <td className="transaction-description">{transaction.description ? transaction.description : "Não informado"}</td>
                      <td className="transaction-status">{transactionIconMap[transaction.status]}{transactionMap[transaction.status]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Wallet;
