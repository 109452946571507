import { methodMap } from "./map";

export default function renderPaymentMethod(payments, isIFood = true) {
    let renderText = '';
    if(isIFood) {
        const chargeOnDelivery = payments.pending > 0;
        if (chargeOnDelivery) {
            const offlineMethods = payments.methods.filter(payment => payment.type === "OFFLINE");
            renderText = `Cobrar do cliente - ${methodMap[offlineMethods[0].method]} - ${offlineMethods[0].method !== "CASH" ? offlineMethods[0].card.brand : 'Dinheiro'} via loja`
        } else {
            const onlineMethods = payments.methods.filter(payment => payment.type === "ONLINE");
            renderText = `Pago via iFood - ${methodMap[onlineMethods[0].method]} - ${onlineMethods[0].card.brand}`
        }
    
        return renderText;
    } else {
        const chargeOnDelivery = !payments[0].prepaid;
        if (chargeOnDelivery) {
            const offlineMethods = payments.filter(payment => !payment.prepaid);
            renderText = `Cobrar do cliente - ${methodMap[offlineMethods[0].code]} - ${offlineMethods[0].code !== "money" ? offlineMethods[0].cardSelected : 'Dinheiro'} via loja`
        } else {
            const onlineMethods = payments.filter(payment => payment.prepaid);
            renderText = `Pago via AnotaAi - ${methodMap[onlineMethods[0].code]} - ${onlineMethods[0].cardSelected}`
        }

        return renderText
    }
}