import React, { useState } from 'react';
import '../assets/scss/filters.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';

const OrderFilters = ({ onApplyFilters }) => {
    const [filters, setFilters] = useState({
        startDate: new Date().toISOString().slice(0, 10), // Data no formato YYYY-MM-DD
        endDate: new Date().toISOString().slice(0, 10),
        deliveryCode: "",
        customerName: "",
        page: 1,
        status: "PENDING"
    });

    // Função para lidar com mudanças nos inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const cleanFilters = () => {
        onApplyFilters({
            startDate: new Date().toISOString().slice(0, 10),
            endDate: new Date().toISOString().slice(0, 10),
            deliveryCode: "",
            customerName: "",
            page: 1
        });
    }

    // Lida com a aplicação dos filtros
    const handleApplyFilters = () => {
        onApplyFilters(filters);
    };

    return (
        <div className="filters">
            <div className="filters-fields">
                {/* Data Inicial */}
                <div className='filters-form start-date'>
                    <label htmlFor="startDate">
                        Data Inicial
                    </label>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={filters.startDate}
                        onChange={handleChange}
                    />
                </div>

                {/* Data Final */}
                <div className='filters-form end-date'>
                    <label htmlFor="endDate">
                        Data Final
                    </label>
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={filters.endDate}
                        onChange={handleChange}
                    />
                </div>

                {/* Código de Entrega */}
                <div className='filters-form delivery-code'>
                    <label htmlFor="deliveryCode">
                        Código de Entrega
                    </label>
                    <input
                        type="text"
                        id="deliveryCode"
                        name="deliveryCode"
                        value={filters.deliveryCode}
                        onChange={handleChange}
                        placeholder="Digite o código"
                    />
                </div>

                {/* Nome do Cliente */}
                <div className='filters-form customer-name'>
                    <label htmlFor="customerName">
                        Nome do Cliente
                    </label>
                    <input
                        type="text"
                        id="customerName"
                        name="customerName"
                        value={filters.customerName}
                        onChange={handleChange}
                        placeholder="Digite o nome do cliente"
                    />
                </div>
            </div>

            {/* Botões */}
            <div className="filters-buttons">
                <button
                    type="button"
                    onClick={handleApplyFilters}
                    className="button-search"
                >
                    <FontAwesomeIcon icon={faSearch} />
                </button>
                <button
                    type="button"
                    onClick={cleanFilters}
                    className="clean-button"
                >
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
        </div>
    );
};

export default OrderFilters;
