import React from "react";
import section1 from "../img/motum-van.png"
import "./css/mydelivery.scss"

//Estruturas do MUI
import Grid from '@mui/material/Grid2';

//Ícones do MUI
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';


export default function MyDelivery() {
    return (
        <React.Fragment>
        <div style={{width:"100%", height:"664px", backgroundImage:""}}>
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={4} className="mydelivery-section-1">
            <div className="titulo-mydelivery" variant="h2" gutterBottom>
                Faça Envios rápidos e seguros com a Motum.
            </div>
            <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: "50px", maxWidth: "350px", ml: "50px"  }}
                    >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Rastrear encomenda"
                        inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            
            </Grid>
            <Grid size={8} className="second-section-1" sx={{ backgroundImage: `url(${section1})`, backgroundSize: "cover"}}>
            </Grid>

            </Grid>

            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'flex', lg:'none'}}}>
            <Grid size={12} className="primary-section-1" sx={{ backgroundImage: `url(${section1})`, backgroundSize: "cover"}}>
            <div className="titulo-home-lg" variant="h2" gutterBottom>
                Faça Envios rápidos e seguros com a Motum.
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: "50px", maxWidth: "218px", ml: "5px"  }}
                    >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Rastrear encomenda"
                        inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </div>
            
            </Grid>

            </Grid>
            
            </div>
            </React.Fragment>
    );
}