import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import '../assets/scss/cancellation.scss';

const IFoodModal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="cancellation-ifood-modal-overlay">
      <div className="cancellation-ifood-modal-content">
        <button className="cancellation-ifood-modal-close" onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} color='#EA1D2C' style={{height: 20}}/>
        </button>
        {children}
      </div>
    </div>
  );
};

export default IFoodModal;
