import React, { useContext, useState } from "react";
import motumIcon from "./../../../assets/img/motum-logo.png";
import HomePage from "./components/HomePage";
import MyDelivery from "./components/MyDelivery";
import Services from "./components/Services";
import Partners from "./components/Partners";
import { Navigate } from "react-router";
import './style.scss';
import { UserContext } from "../../context/UserContext";
import Menu from "@mui/material/Menu";

//MUI
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid2'

export default function Home(props) {
    const [anchorNav, setAnchorNav] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [selectedPage, setSelectedPage] = useState("Home");
    const [previousPage, setPreviousPage] = useState(null);
    const { hideSidebar, setHideSidebar, user, selectedStore } = useContext(UserContext);

    const openMenu = (event) => {
        setAnchorNav(event.currentTarget); // Ajustado para definir o ponto de ancoragem correto
    };

    const closeMenu = () => {
        setAnchorNav(null);
    };

    if (redirect) {
        if (user && selectedStore) {
            setHideSidebar(false);
            return <Navigate to={"/request"} />;
        } else {
            setHideSidebar(true);
            return <Navigate to={"/login"} />;
        }
    }

    const handlePageChange = (page) => {
        if (page !== selectedPage) {
            setPreviousPage(selectedPage);
            setSelectedPage(page);
        }
    };

    if (!hideSidebar) {
        setHideSidebar(true);
    }

    return (
        <React.Fragment>
            <body className="home-page">
            <div position="fixed" className="navbar">
                <div className="toolbar">
                    <div size="large" edge="start" color="inherit" aria-label="logo" onClick={() => handlePageChange("Home")}>
                        <img src={motumIcon} alt="Motum" className="motum-logo" />
                    </div>

                    <div className="nav-container">
                        <button className="nav-item" onClick={() => handlePageChange("Services")}>Serviços</button>
                        <button className="nav-item" onClick={() => handlePageChange("MyDelivery")}>Minha Entrega</button>
                        <button className="nav-item" onClick={() => handlePageChange("Partners")}>Parceiros</button>
                        <button className="nav-item">Ferramentas</button>
                        <button className="nav-item">Blog</button>
                        <button className="nav-item">Central de Ajuda</button>
                        <button className="login-button" onClick={() => setRedirect(true)}>Login</button>
                        <button className="create-account-button">Criar Conta</button>
                    </div>
                    <button
                        className="menu-button"
                        edge="end"
                        onClick={openMenu}
                    >
                        <MenuIcon />
                    </button>

                    <Menu
                        anchorEl={anchorNav}
                        open={Boolean(anchorNav)}
                        onClose={closeMenu}
                    >
                        <MenuList>
                            <MenuItem>
                                <button className="nav-item" onClick={() => handlePageChange("Services")}>Serviços</button>
                            </MenuItem>
                            <MenuItem>
                                <button className="nav-item" onClick={() => handlePageChange("MyDelivery")}>Minha Entrega</button>
                            </MenuItem>
                            <MenuItem>
                                <button className="nav-item" onClick={() => handlePageChange("Partners")}>Parceiros</button>
                            </MenuItem>
                            <MenuItem>
                                <button className="nav-item" onClick={() => handlePageChange("Ferramentas")}>Ferramentas</button>
                            </MenuItem>
                            <MenuItem>
                                <button className="nav-item" onClick={() => handlePageChange("Blog")}>Blog</button>
                            </MenuItem>
                            <MenuItem>
                                <button className="nav-item" onClick={() => handlePageChange("Central de Ajuda")}>Central de Ajuda</button>
                            </MenuItem>
                            <MenuItem>
                                <button className="login-button" onClick={() => handlePageChange("Login")}>Login</button>
                            </MenuItem>
                            <MenuItem>
                                <button className="create-account-button" onClick={() => handlePageChange("Criar Conta")}>Criar Conta</button>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>
            <div className="motum-home-main">
                    <div className={`page-transition ${selectedPage === "Home" ? 'fade-in' : 'fade-out'} ${previousPage === "Home" ? 'slide-out' : ''}`}>
                        {selectedPage === "Home" ? (<HomePage setRedirect={setRedirect}/>) : null}
                    </div>
                    <div className={`page-transition ${selectedPage === "MyDelivery" ? 'fade-in' : 'fade-out'} ${previousPage === "MyDelivery" ? 'slide-out' : ''}`}>
                        {selectedPage === "MyDelivery" ? (<MyDelivery />) : null}
                    </div>
                    <div className={`page-transition ${selectedPage === "Services" ? 'fade-in' : 'fade-out'} ${previousPage === "Services" ? 'slide-out' : ''}`}>
                        {selectedPage === "Services" ? (<Services />) : null}
                    </div>
                    <div className={`page-transition ${selectedPage === "Partners" ? 'fade-in' : 'fade-out'} ${previousPage === "Partners" ? 'slide-out' : ''}`}>
                        {selectedPage === "Partners" ? (<Partners />) : null}
                    </div>
                </div>
                <footer>
                    <Grid container spacing={0} sx={{backgroundColor: '#e6e6e6', padding: '50px', display:{xs:'none', lg:'flex'}}}>
                    <Grid size={3}>
                    <div size="large" edge="start" color="inherit" aria-label="logo" onClick={() => handlePageChange("Home")}>
                        <img src={motumIcon} alt="Motum" className="motum-logo" />
                    </div>
                    </Grid>
                    <Grid size={3}>
                        <button className="footer-header" onClick={() => handlePageChange("Services")}>
                            Serviços
                        </button><br />
                        <button className="footer-topic">Motum Hub</button><br />
                        <button className="footer-topic">Motum Cidades</button><br />
                        <button className="footer-topic">Motum Xpress</button><br />
                        <button className="footer-topic">Integração com o iFood</button><br />
                        <button className="footer-header" onClick={() => handlePageChange("MyDelivery")}>
                            Minha entrega
                        </button>
                    </Grid>
                    <Grid size={3}>
                        <button className="footer-header" onClick={() => handlePageChange("Partners")}>
                            Parceiros
                        </button><br />
                        <button className="footer-topic">Entregador</button><br />
                        <button className="footer-topic">Motum Hub</button><br />
                        <button className="footer-topic">Benefícios do Motum Hub</button><br />
                        <button className="footer-topic">Motum Dispatcher</button><br />
                    </Grid>
                    <Grid size={3}>
                        <button className="footer-header" onClick={() => handlePageChange("Blog")}>
                            Blog
                        </button><br />
                        <button className="footer-header" onClick={() => handlePageChange("HelpCenter")}>
                           Central de Ajuda
                        </button><br />
                    </Grid>

                    </Grid>
                    <Grid container spacing={0} sx={{backgroundColor: '#e6e6e6', padding: '50px', display:{xs:'flex', lg:'none'}, textAlign:'center'}}>
                    <Grid size={12}>
                    <div size="large" edge="start" color="inherit" aria-label="logo" onClick={() => handlePageChange("Home")}>
                        <img src={motumIcon} alt="Motum" className="motum-logo" />
                    </div>
                        <button className="footer-header"  onClick={() => handlePageChange("Services")}>
                            Serviços
                        </button><br />
                        <button className="footer-topic">Motum Hub</button><br />
                        <button className="footer-topic">Motum Cidades</button><br />
                        <button className="footer-topic">Motum Xpress</button><br />
                        <button className="footer-topic">Integração com o iFood</button><br />
                        <button className="footer-header"   onClick={() => handlePageChange("MyDelivery")}>
                            Minha entrega
                        </button><br />
                        <button className="footer-header"   onClick={() => handlePageChange("Partners")}>
                            Parceiros
                        </button><br />
                        <button className="footer-topic">Entregador</button><br />
                        <button className="footer-topic">Motum Hub</button><br />
                        <button className="footer-topic">Benefícios do Motum Hub</button><br />
                        <button className="footer-topic">Motum Dispatcher</button><br />
                        <button className="footer-header"   onClick={() => handlePageChange("Blog")}>
                            Blog
                        </button><br />
                        <button className="footer-header"   onClick={() => handlePageChange("HelpCenter")}>
                           Central de Ajuda
                        </button><br />
                    </Grid>
                    </Grid>
                    <div style={{backgroundColor:'#e6e6e6', padding:'10px', textAlign:'center'}}>© 2025 Motum Brasil. Todos os direitos reservados.</div>
                </footer>
            </body>
        </React.Fragment>
    );
}
