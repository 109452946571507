import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import AdminService from "../../../../../services/AdminService";
import Conversor from "../../../../../helper/Conversor";
import Swal from "sweetalert2";
import './style.scss';

export default function RegisterSupport(props) {
    const { user } = useContext(UserContext);

    const [formData, setFormData] = useState({
        userId: props.selectedUser._id,
        description: '',
        status: 'Open',
        assignedTo: user._id
    });

    const [loadingUsers, setLoadingUsers] = useState(false);
    const [users, setUsers] = useState([]);
    const [loadingSupports, setLoadingSupports] = useState(false);
    const [supports, setSupports] = useState([]);

    const adminService = useMemo(() => new AdminService(), []);

    async function loadSupports() {
        if (loadingSupports) {
            return;
        }
        setLoadingSupports(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const response = await adminService.getSupports();
            setSupports(response);
            props.setSpinner(false)
        }, 2000);
    }

    useEffect(() => {
        loadSupports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSupports]);

    async function loadUsers() {
        if (loadingUsers) {
            return;
        }
        setLoadingUsers(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const response = await adminService.getUsers();
            setUsers(response);
            props.setSpinner(false)
        }, 2000);
    }

    const getUserName = (userId) => {
        const user = users.find((user) => user._id === userId);
        return user ? user.name : 'Usuário não encontrado';
    };

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            props.setSpinner(true);
            await adminService.createSupport(formData);
            props.setSpinner(false);
            Swal.fire({
                icon: 'success',
                title: 'Suporte adicionado!',
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro',
                text: error.message,
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    const status_map = {
        "Open": "Aberto",
        "In Progress": "Em andamento",
        "Closed": "Fechado"
    }

    return (
        <div className="content-container register-support-container">
            <div className="support-form">
                <div className="title">Cadastrar novo suporte</div>
                <form onSubmit={handleSubmit}>
                    <div className="form-control">
                        <label htmlFor="userId">Usuário:</label>
                        <input
                            disabled={true}
                            id="userId"
                            name="userId"
                            type="text"
                            value={props.selectedUser.name}
                            required
                        />
                    </div>

                    <div className="form-control">
                        <label htmlFor="description">Descrição:</label>
                        <textarea
                            id="description"
                            name="description"
                            placeholder="Detalhe qual ajuda você concedeu ao usuário"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit">Salvar</button>
                    <div className="back-action">
                        <button className="back-btn" onClick={props.handleBackMenu}>Voltar</button>
                    </div>
                </form>
            </div>
            <div className="support-list">
                <div className="title">Últimos suportes</div>
                <div className="support-list-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Usuário</th>
                                <th>Descrição</th>
                                <th>Status</th>
                                <th>Criado em</th>
                                <th>Última atualização</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supports.map((support) => (
                                <tr key={support._id}>
                                    <td>{getUserName(support.userId)}</td>
                                    <td>{support.description}</td>
                                    <td>{status_map[support.status]}</td>
                                    <td>{Conversor.converterData(support.createdAt)}</td>
                                    <td>{Conversor.converterData(support.updatedAt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}