import React, { useState, useEffect } from "react";
import section1 from "../img/deliveryboy-motum.png"
import section1lg from "../img/deliveryboy-motum-lg.png"
import check from "../img/check.png"
import employee from "../img/employee.svg"
import checksmall from "../img/check-small.png"
import list from "../img/list.png"
import mobilefriendly from "../img/mobile-friendly.png"
import box from "../img/box.png"
import courier from "../img/courier.svg"
import "./css/partners.scss"

//Estruturas do MUI
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Partners() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLargeScreen, setIsLargeScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
        setIsLargeScreen(window.innerWidth >= 1400);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Chama inicialmente para ajustar o estado

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const carouselItems = [
        {
          title: "Melhora no Fluxo de Solicitação Entregas",
          content: "Solicitação com poucos cliques ou automatizada",
          icon: box,  // Remover as chaves aqui
        },
        {
          title: "Contabilidade Integrada",
          content: "Todo fluxo de pagamentos é feito dentro da plataforma, dê adeus as comandas.",
          icon: checksmall,
        },
        {
          title: "Gestão de Frota em Tempo Real",
          content: "Acompanhe seus entregadores através do mapa.",
          icon: mobilefriendly,
        },
        {
          title: "Integrações Com Plataformas",
          content: "Possibilite ao seu cliente uma solicitação sem cliques.",
          icon: list,
        },
      ];
    
      const nextItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
      };
    
      const prevItem = () => {
        setCurrentIndex(
          (prevIndex) => (prevIndex - 1 + carouselItems.length) % carouselItems.length
        );
      };

    return (
        <React.Fragment>
        <div>
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={6} className="partners-section-1" sx={{ backgroundImage: `url(${section1})`, backgroundSize: "cover"}}>
            </Grid>
            <Grid size={6} className="partners-section-1">
            <div className="titulo-partners">
                Entregador
            </div>
            <div className="subtitulo-partners">
                Ganhe Dinheiro Fazendo Entregas
            </div>
            <div className="benefit-partners">
                <img src={check} className="check" />
                Aceite as entregas que melhor se encaixam a sua rotina
            </div>
            <div className="benefit-partners">
                <img src={check} className="check" />
                Controle de ganhos
            </div>
            <div className="benefit-partners">
                <img src={check} className="check" />
                Suporte humanizado
            </div>
            <button className="button-partners">
                Venha fazer parte
            </button>
            
            </Grid>

            </Grid>

            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'flex', lg:'none'}}}>
            <Grid size={12} className="partners-section-1-lg" sx={{ backgroundImage: `url(${section1lg})`, backgroundSize: "cover", backgroundColor: "#F6F6F6"}}>
            
            </Grid>
            <div className="partners-section-1-lg">
                <div className="titulo-partners-lg">
                    Entregador
                </div>
                <div className="subtitulo-partners-lg">
                    Ganhe Dinheiro Fazendo Entregas
                </div>
                <div className="benefit-partners-lg">
                    <img src={check} className="check-lg" />
                    Aceite as entregas que melhor se encaixam a sua rotina
                </div>
                <div className="benefit-partners-lg">
                    <img src={check} className="check-lg" />
                    Controle de ganhos
                </div>
                <div className="benefit-partners-lg">
                    <img src={check} className="check-lg" />
                    Suporte humanizado
                </div>
                <button className="button-partners">
                    Venha fazer parte
                </button>   
            </div>
            </Grid>
            
            </div>

            <div>
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={4} className="partners-section-2">
            <div className="titulo-partners-section-2" variant="h2">
                Motum Hub
            </div>
            <div className="subtitulo-partners-section-2" variant="h2">
                O Motum Hub é pra você que quer aumentar sua carteira de Clientes e melhorar o dia dia de distribuição na sua região.
            </div>
            <button className="button-partners-section-2">
                    Venha fazer parte
            </button>
            
            </Grid>
            <Grid size={8} className="second-section-1" sx={{ backgroundImage: `url(${employee})`, backgroundSize: "cover"}}>
            </Grid>

            </Grid>

            <Grid className="" container spacing={0} sx={{display:{xs:'flex', lg:'none'}}}>
            <Grid size={12} sx={{ backgroundImage: `url(${employee})`, backgroundSize: "cover", height:"300px"}}>
            
            </Grid>
            <div className="secondary-section-2-lg">
                <div className="titulo-partners-section-2-lg" variant="h2">
                    Motum Hub
                </div>
                <div className="subtitulo-partners-section-2-lg" variant="h2">
                    O Motum Hub é pra você que quer aumentar sua carteira de Clientes e melhorar o dia dia de distribuição na sua região.
                </div>
                <button className="button-partners-section-2-lg">
                    Venha fazer parte
                </button>
            </div>
            </Grid>
            
            </div>
            <div className="partners-section-3">
                <div className="titulo-partners-section-3" variant="h2">
                Benefícios do Motum Hub
                </div>
                <Grid sx={{display:"flex", justifyContent:"center", '@media (max-width: 1399px)': { display: 'none' }}}>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center"}}>
                            <CardContent>
                                <div className="card-title" variant="h5" component="div">
                                Melhora no Fluxo de Solicitação Entregas
                                </div>
                                <div className="card-content" variant="body1">
                                Solicitação com poucos cliques ou automatizada
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <img src={box} className="card-icon" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center", ml: "50px" }}>
                            <CardContent>
                                <div className="card-title" variant="h5" component="div">
                                Contabilidade Integrada
                                </div>
                                <div className="card-content" variant="body1">
                                Todo fluxo de pagamentos é feito dentro da platafora, dê adeus as comandas.
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <img src={checksmall} className="card-icon" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center", ml: "50px" }}>
                            <CardContent>
                                <div className="card-title" variant="h5" component="div">
                                Gestão de Frota em Tempo Real
                                </div>
                                <div className="card-content" variant="body1">
                                Acompanhe seus entregadores atrávés do mapa.
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <img src={mobilefriendly} className="card-icon" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center", ml: "50px" }}>
                            <CardContent>
                                <div className="card-title" variant="h5" component="div">
                                Integrações Com Plataformas 
                                </div>
                                <div className="card-content" variant="body1">
                                Possibilite ao seu cliente uma solicitação sem cliques.
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <img src={list} className="card-icon" />
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <div
                    style={{
                        display: isLargeScreen ? 'none' : 'flex',
                        justifyContent: 'center',
                    }}
                    >
                    <button className = "left-button-lg" onClick={prevItem} sx={{ marginRight: 2 }}>
                        <ArrowBackIcon />
                    </button>

                    <Card className="card-homepage" sx={{ width: "290px", textAlign: "center"}}>
                            <CardContent>
                                <div className="card-title-small" variant="h5" component="div">
                                {carouselItems[currentIndex].title}
                                </div>
                                <div className="card-content-small" variant="body1">
                                {carouselItems[currentIndex].content}
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <img src={carouselItems[currentIndex].icon} className="check" style={{height:'40px', width:'40px'}} />
                            </CardActions>
                        </Card>

                    <button className = "right-button-lg" onClick={nextItem} sx={{ marginLeft: 2 }}>
                        <ArrowForwardIcon />
                    </button>
                    </div>
            </div>
            
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={4} className="partners-section-4">
            <div className="titulo-partners-section-2" variant="h2">
                Motum Dispatcher
            </div>
            <div className="subtitulo-partners-section-2" variant="h2">
                Criado para transportadoras que querem se conectar com o Brasil.
            </div>
            <button className="button-partners-section-2">
                    Venha fazer parte
            </button>
            
            </Grid>
            <Grid size={8} className="second-section-1" sx={{ backgroundImage: `url(${courier})`, backgroundSize: "cover"}}>
            </Grid>

            </Grid>    
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'flex', lg:'none'}}}>
            <Grid size={12} className="primary-section-4-lg" sx={{ backgroundImage: `url(${courier})`, backgroundSize: "cover", backgroundPosition:"bottom"}}>
            </Grid>
            <Grid size={12} sx={{height:"300px", backgroundColor:"white", display:"flex", alignItems:"center"}}>
            <div className="secondary-section-2-lg">
                <div className="titulo-partners-section-2-lg" variant="h2">
                Motum Dispatcher
                </div>
                <div className="subtitulo-partners-section-2-lg" variant="h2">
                Criado para transportadoras que querem se conectar com o Brasil.
                </div>
                <button className="button-partners-section-2-lg">
                    Venha fazer parte
                </button>
            </div>
            
            </Grid>

            </Grid>        
            </React.Fragment>
    );
}