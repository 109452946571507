import React from "react";
import "./style.scss";

const DataPrivacyPolicy = () => {
  return (
    <React.Fragment>
      <div className="data-privacy-policy">
        <section className="policy-intro">
          <h1 className="policy-title">Política de Privacidade</h1>

          <p className="policy-text">
            A Política de Privacidade ("Política de Privacidade") que você está
            acessando integra os Termos e Condições de Uso e descreve como a
            MOTUM NOVA SIMPLES I.S. ("Motum") realiza a coleta, armazenamento,
            uso, processamento, associação, proteção, compartilhamento e demais
            formas de tratamento das suas informações, incluindo os seus dados
            pessoais, em conexão com o Aplicativo e os Serviços regidos pelos
            Termos e Condições de Uso.
          </p>
          <br />
          <p className="policy-text">
            A Motum está comprometida com a sua privacidade e, por isso,
            disponibiliza esta Política de Privacidade em seu aplicativo para
            que você possa acessar a versão mais atualizada sempre que desejar.
          </p>
          <br />
        </section>

        <section className="policy-sections">
          <h2 className="section-title">Seções desta Política de Privacidade</h2>
          <ol className="section-list">
            <li className="section-item">Dados coletados</li>
            <li className="section-item">Proteção e Armazenamento</li>
            <li className="section-item">Uso e Compartilhamento</li>
            <li className="section-item">Consentimento</li>
            <li className="section-item">Exclusão de dados pessoais</li>
          </ol>
        </section>

        <section className="policy-details">
          <h2 className="section-title">1. Dados Coletados</h2>
          <p className="policy-text">
            Quando você se cadastra e utiliza os Serviços, você fornece e a
            Motum coleta informações estatísticas de utilização dos Serviços,
            dados agregados e dados de identificação pessoal. Essas informações
            passam a ser tratadas conforme esta Política de Privacidade.
          </p>
          <br />
          <p className="policy-text">
            A Motum pode coletar suas informações, incluindo dados aptos a
            identificar você, em diversas circunstâncias, como:
          </p>
          <br />
          <ul className="data-list">
            <li className="data-item">Quando da criação do seu cadastro na Motum;</li>
            <li className="data-item">
              Quando você acessa nosso aplicativo ou utiliza Serviços da Motum;
            </li>
            <li className="data-item">
              Durante a utilização do Aplicativo, enquanto este permanecer
              ativo.
            </li>
          </ul>

          <p className="policy-text">
            As informações coletadas podem incluir nome, foto, endereço,
            telefone, e-mail, número de CPF, entre outros dados necessários para
            a prestação dos Serviços.
          </p>
        </section>

        <section className="policy-details">
          <h2 className="section-title">2. Proteção e Armazenamento</h2>
          <p className="policy-text">
            A segurança dos seus dados é prioridade. A Motum emprega práticas de
            segurança adequadas, como criptografia, SSL, firewalls e controle de
            acesso.
          </p>
          <br />
          <p className="policy-text">
            As informações podem ser armazenadas em servidores no Brasil ou no
            exterior, sujeitos às mesmas medidas de segurança descritas.
          </p>
        </section>

        <section className="policy-details">
          <h2 className="section-title">3. Uso e Compartilhamento</h2>
          <p className="policy-text">
            As suas informações são utilizadas para prestar, aprimorar e
            expandir os Serviços da Motum. Elas podem ser compartilhadas somente
            em hipóteses específicas, como:
          </p>
          <ul className="data-list">
            <li className="data-item">Quando autorizado por você;</li>
            <li className="data-item">Para subsidiárias e parceiros comerciais;</li>
            <li className="data-item">Em cumprimento de obrigações legais ou ordens judiciais.</li>
          </ul>
        </section>

        <section className="policy-details">
          <h2 className="section-title">4. Consentimento</h2>
          <p className="policy-text">
            Ao instalar o Aplicativo e utilizar os Serviços da Motum, você
            consente expressamente com o tratamento dos seus dados conforme
            descrito nesta Política de Privacidade.
          </p>
        </section>

        <section className="policy-details">
          <h2 className="section-title">5. Exclusão de Dados Pessoais</h2>
          <p className="policy-text">
            Para excluir seus dados pessoais, você deve solicitar diretamente à
            Motum e desinstalar o Aplicativo. Alguns dados poderão ser mantidos
            para cumprimento de obrigações legais pelo prazo mínimo necessário.
          </p>
          <br />
          <p className="policy-text">
            Em caso de dúvidas e sugestões, entre em contato com nossos canais
            de comunicação ao usuário.
          </p>
        </section>
      </div>
    </React.Fragment>
  );
};

export default DataPrivacyPolicy;
