import { useEffect, useMemo, useState } from "react";
import Conversor from "../../../../../helper/Conversor";
import AdminService from "../../../../../services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import './style.scss';

export default function Logs(props) {
    const [logs, setLogs] = useState([]);
    const [loadingLogs, setLoadingLogs] = useState(false);
    const adminService = useMemo(() => new AdminService(), []);

    async function loadLogs() {
        if (loadingLogs) {
            return;
        }
        setLoadingLogs(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const response = await adminService.getLogs();
            setLogs(response);
            props.setSpinner(false)
        }, 2000);
    }

    useEffect(() => {
        loadLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingLogs]);

    return (
        <div className="content-container logs-container">
            <div className="activity-logs-container">
                <div className="activity-logs-title">Atividades no Sistema</div>
                <div className="activity-logs-list">
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Tipo</th>
                                <th>Usuário</th>
                                <th>Descrição</th>
                                <th>Status</th>
                                <th>Recurso</th>
                                <th>Localização</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs.length > 0 && logs.map((log) => (
                                <tr>
                                    <td>{Conversor.converterData(log.timestamp)}</td>
                                    <td>{log.activityType}</td>
                                    <td>{log.userName}</td>
                                    <td>{log.description}</td>
                                    <td className='status-label'>{log.status === "Success" ? <FontAwesomeIcon icon={faCheckSquare} color='green' /> : <FontAwesomeIcon icon={faSquareXmark} color='red' />}</td>
                                    <td>{log.resourceId}</td>
                                    <td>{log.ipAddress}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="back-action">
                <button className="back-btn" onClick={props.handleBackMenu}>Voltar</button>
            </div>
        </div>
    );
}