import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import XpressRequest from "./view/pages/XpressRequest/XpressRequest";
import FollowUp from "./view/pages/FollowUp/FollowUp";
import DeliveriesMade from "./view/pages/DeliveriesMade/DeliveriesMade";
import CanceledDeliveries from "./view/pages/CanceledDeliveries/CanceledDeliveries";
import Wallet from "./view/pages/Wallet/Wallet";
import Error from "./view/pages/Error/Error";
import Login from "./view/pages/Login/Login";
import Register from "./view/pages/Register/Register";
import Success from "./view/pages/Success/Success";
import Conclusion from "./view/pages/Conclusion/Conclusion";
import DataPrivacyPolicy from "./view/pages/DataPrivacyPolicy/DataPrivacyPolicy";
import { UserContext, UserProvider } from "./view/context/UserContext";
import { SocketProvider } from "./view/context/SocketContext";
import LoadingSpinner from "./view/components/Loading";
import Management from "./view/pages/Management/Management";
import OrderManagement from "./view/pages/OrderManagement/OrderManagement";
import Home from "./view/pages/Home/Home";
import CustomSidebar from "./view/components/Sidebar/CustomSidebar";
import ProtectedRoute from "./view/components/ProtectedRoute";
import Admin from "./view/pages/Admin/Admin";
import "./assets/css/App.scss";

const AppContent = () => {
    const { hideSidebar } = useContext(UserContext);
    const [spinner, setSpinner] = useState(false);
    const [textSpinner, setTextSpinner] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <>
            <LoadingSpinner visible={spinner} text={textSpinner} />
            <BrowserRouter>
                <main className="main">
                    {
                        !hideSidebar &&
                        <CustomSidebar windowWidth={windowWidth} setSpinner={setSpinner} setTextSpinner={setTextSpinner} />}
                    <Routes>
                        <Route path="/" element={<Home setSpinner={setSpinner} setTextSpinner={setTextSpinner} windowWidth={windowWidth} />} />
                        <Route path='/terms' element={<DataPrivacyPolicy />} />
                        <Route path="/login" element={<Login setSpinner={setSpinner} setTextSpinner={setTextSpinner} />} />
                        <Route path="/register" element={<Register setSpinner={setSpinner} setTextSpinner={setTextSpinner} />} />
                        <Route path="/request" element={<ProtectedRoute><XpressRequest setSpinner={setSpinner} setTextSpinner={setTextSpinner} windowWidth={windowWidth} /></ProtectedRoute>} />
                        <Route path="/management" element={<ProtectedRoute><Management setSpinner={setSpinner} setTextSpinner={setTextSpinner} windowWidth={windowWidth} /></ProtectedRoute>} />
                        <Route path="/ordermanagement" element={<ProtectedRoute><OrderManagement setSpinner={setSpinner} setTextSpinner={setTextSpinner} windowWidth={windowWidth} /></ProtectedRoute>} />
                        <Route path="/followup" element={<ProtectedRoute><FollowUp setSpinner={setSpinner} setTextSpinner={setTextSpinner} windowWidth={windowWidth} /></ProtectedRoute>} />
                        <Route path="/deliveriesmade" element={<ProtectedRoute><DeliveriesMade setSpinner={setSpinner} setTextSpinner={setTextSpinner} windowWidth={windowWidth} /></ProtectedRoute>} />
                        <Route path="/canceleddeliveries" element={<ProtectedRoute><CanceledDeliveries setSpinner={setSpinner} setTextSpinner={setTextSpinner} windowWidth={windowWidth} /></ProtectedRoute>} />
                        <Route path="/wallet" element={<ProtectedRoute><Wallet setSpinner={setSpinner} setTextSpinner={setTextSpinner} windowWidth={windowWidth} /></ProtectedRoute>} />
                        <Route path="/support" element={<ProtectedRoute><Admin setSpinner={setSpinner} setTextSpinner={setTextSpinner} windowWidth={windowWidth} /></ProtectedRoute>} />
                        <Route path="/success" element={<ProtectedRoute><Success /></ProtectedRoute>} />
                        <Route path="/conclusion" element={<ProtectedRoute><Conclusion /></ProtectedRoute>} />
                        <Route path='*' element={<Error />} />
                    </Routes>
                </main>
            </BrowserRouter>
        </>
    );
}

const App = () => {
    return (
        <SocketProvider>
            <UserProvider>
                <AppContent />
            </UserProvider>
        </SocketProvider>
    );
}

export default App;
