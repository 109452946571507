import OrderDetails from './OrderDetails';
import '../assets/scss/modal.scss';

export default function OrderModal({ order, setSpinner, handleShowModal }) {
    return (
        <div className="order-modal">
            <div className="order-modal-content">
                <OrderDetails order={order} setSpinner={setSpinner} handleShowModal={handleShowModal} />
            </div>
        </div>
    );
}