export default function formatarData(dataString) {
    const data = new Date(dataString);
    const hoje = new Date();
    const ontem = new Date(hoje);
    ontem.setDate(hoje.getDate() - 1); // Define a data de ontem

    // Compara o ano, mês e dia
    const mesmaDataHoje = data.toDateString() === hoje.toDateString();
    const mesmaDataOntem = data.toDateString() === ontem.toDateString();

    const horas = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Mês começa do 0, então somamos 1

    if (mesmaDataHoje) {
        return `Hoje às ${horas}h${minutos}`;
    } else if (mesmaDataOntem) {
        return `Ontem às ${horas}h${minutos}`;
    } else {
        return `${dia}/${mes} às ${horas}h${minutos}`;
    }
}