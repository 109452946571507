export default function renameKeys(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(item => renameKeys(item));
    }

    const newObj = {};
    for (let key in obj) {
        let newKey = key === '_id' ? 'id' : key;
        newObj[newKey] = renameKeys(obj[key]);
    }
    return newObj;
}