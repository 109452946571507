import React from 'react';
import Conversor from '../../../helper/Conversor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCheck
} from '@fortawesome/free-solid-svg-icons';

const UsersList = ({ users, nextPage, lastPage,
  aproveUser, disaproveUser, title }) => {

  return (
    <div className="table-orders-container">
      <h1 className="page-title" style={{ marginBottom: 30 }}>{title}</h1>
      <table style={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th className='th-delivery-code'>Data de Cadastro</th>
            <th>Tipo</th>
            <th>Nome</th>
            <th>CPF</th>
            <th>Loja/Veículo</th>
            <th>CPNJ/Placa</th>
            <th style={{ width: "10%", textAlign: 'center' }}>Aprovar/Reprovar</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            if (user.store) {
              return (
                <tr key={user._id}>
                  <td className='td-delivery-code'>{Conversor.converterData(user.created)}</td>
                  <td>Lojista</td>
                  <td>{user.name}</td>
                  <td>{Conversor.converterCpf(user.cpf.toString())}</td>
                  <td>{user.store.companyName}</td>
                  <td>{Conversor.converterCNPJ(user.store.cnpj)}</td>
                  <td className='td-actions-buttons' style={{ textAlign: 'center' }}>
                    <button className="btn btn-info" style={{ backgroundColor: "#218838", width: 150, marginRight: 30 }} onClick={() => aproveUser(user._id, user.email)}>
                      <FontAwesomeIcon icon={faCheck} title='Aprovar Usuário' style={{ marginRight: 10, height: 15 }} />
                      Aprovar
                    </button>
                    <button className="btn btn-danger cancel-order-btn" onClick={() => disaproveUser(user._id, user.email)} style={{ width: 150, marginRight: 30 }}>
                      <FontAwesomeIcon icon={faTimes} color='white' title='Reprovar Usuário' style={{ marginRight: 10, height: 15 }} />
                      Reprovar
                    </button>
                  </td>
                </tr>
              );
            } else {
              return (
                <tr key={user._id}>
                  <td className='td-delivery-code'>{Conversor.converterData(user.created)}</td>
                  <td>Entregador</td>
                  <td>{user.name}</td>
                  <td>{Conversor.converterCpf(user.cpf.toString())}</td>
                  <td>{user.metadata.vehicle.model}</td>
                  <td>{user.metadata.vehicle.vehiclePlate}</td>
                  <td className='td-actions-buttons' style={{ textAlign: 'center', display: 'inline-flex' }}>
                    <button className="btn btn-info" style={{ backgroundColor: "#218838", width: 100, marginRight: 10, fontSize: 12 }} onClick={() => aproveUser(user._id, user.email)}>
                      <FontAwesomeIcon icon={faCheck} title='Aprovar Usuário' style={{ marginRight: 10, height: 10 }} />
                      Aprovar
                    </button>
                    <button className="btn btn-danger cancel-order-btn" onClick={() => disaproveUser(user._id, user.email)} style={{ width: 100, marginRight: 10, fontSize: 12 }}>
                      <FontAwesomeIcon icon={faTimes} color='white' title='Reprovar Usuário' style={{ marginRight: 10, height: 10 }} />
                      Reprovar
                    </button>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UsersList;