import HttpService from "./HttpService.js";
import axios from "axios";

export default class WalletService {
    constructor() {
        this._http = new HttpService();
        this._baseUrl = `${process.env.REACT_APP_DELIVERIES_API}/wallet`;
    }

    async loadWallet(user_id) {
        const response = await this._http.get(this._baseUrl + `/${user_id}`);
        return response;
    }

    async newIntentDeposit(storeId, amount) {
        const url = this._baseUrl + `/${storeId}/deposits`;
        const body = {
            "amount": amount,
            "description": "Novo deposito via PIX"
        };
        const { data } = await axios.post(url, body);
        return data;
    }
}
