import React, { useState, useEffect } from "react";
import section1 from "../img/homepage.svg"
import section3 from "../img/pacote.svg"
import section4 from "../img/celular.svg"
import "./css/homepage.scss"

//Estruturas do MUI
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

//Ícones do MUI
import BoltIcon from '@mui/icons-material/Bolt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function HomePage(props){
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLargeScreen, setIsLargeScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
        setIsLargeScreen(window.innerWidth >= 1400);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Chama inicialmente para ajustar o estado

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const carouselItems = [
        {
          title: "Motum Hub",
          content: "Comece a ter envios rápidos e econômicos, a partir de R$5,49",
          icon: <BoltIcon/>
        },
        {
          title: "Motum Cidades",
          content: "Envie Para Qualquer Lugar do Brasil com Motum Cidades",
          icon: <LocationOnIcon/>
        },
        {
          title: "Motum Xpress",
          content: "Entregando seu pedido em até 30 minutos",
          icon: <AccessTimeFilledIcon/>
        },
        {
          title: "Integrações",
          content: "Adicione os seus pedidos automaticamente",
          icon: ""
        },
      ];
    
      const nextItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
      };
    
      const prevItem = () => {
        setCurrentIndex(
          (prevIndex) => (prevIndex - 1 + carouselItems.length) % carouselItems.length
        );
      };

    return (
        <React.Fragment>
        <div>
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={4} className="primary-section-1">
            <div className="titulo-home" variant="h2" gutterBottom>
                A BLACK FRIDAY chegou na MOTUM!
                <div className="subtitulo-home" variant="h5" gutterBottom>
                Todas as entregas com até 30% de desconto, faça já o seu pedido.
                </div>
                <button className="send-now-button" sx={{ mb: "12px" }} >Enviar Agora</button>
                <button className="tracking-button" sx={{ mb: "12px" }} >Rastrear Minha Encomenda</button>
            </div>
            
            </Grid>
            <Grid size={8} className="second-section-1" sx={{ backgroundImage: `url(${section1})`, backgroundSize: "cover"}}>
            </Grid>

            </Grid>

            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'flex', lg:'none'}}}>
            <Grid size={12} className="primary-section-1" sx={{ backgroundImage: `url(${section1})`, backgroundSize: "cover"}}>
            <div className="titulo-home-lg" variant="h2" gutterBottom>
                A BLACK FRIDAY chegou na MOTUM!
                <div className="subtitulo-home-lg" variant="h5" gutterBottom>
                Todas as entregas com até 30% de desconto, faça já o seu pedido.
                </div>
                <button className="send-now-button" sx={{ mb: "12px" }} >Enviar Agora</button>
                <button className="tracking-button" sx={{ mb: "12px" }} >Rastrear Minha Encomenda</button>
            </div>
            
            </Grid>

            </Grid>
            
            </div>
            <div className="secondary-section">
                <div className="titulo-secondary-section" variant="h2" gutterBottom>
                A Motum Entrega onde seu cliente estiver!
                </div>
                <Grid sx={{display:"flex", justifyContent:"center", '@media (max-width: 1399px)': { display: 'none' }}}>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center"}}>
                            <CardContent>
                                <div className="card-title" variant="h5" component="div">
                                <BoltIcon/>
                                Motum Hub
                                </div>
                                <div className="card-content" variant="body1">
                                Comece a ter envios rápidos e econômicos,  a partir de R$5,49
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <button className="card-button" size="small">Saiba mais</button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center", ml: "50px" }}>
                            <CardContent>
                                <div className="card-title" variant="h5" component="div">
                                <LocationOnIcon/>
                                Motum Cidades
                                </div>
                                <div className="card-content" variant="body1">
                                Envie Para Qualquer Lugar do Brasil com Motum Cidades
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <button className="card-button" size="small">Saiba mais</button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center", ml: "50px" }}>
                            <CardContent>
                                <div className="card-title" variant="h5" component="div">
                                <AccessTimeFilledIcon/>
                                Motum Xpress
                                </div>
                                <div className="card-content" variant="body1">
                                Entregando seu pedido em até 30 minutos
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <button className="card-button" size="small">Saiba mais</button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center", ml: "50px" }}>
                            <CardContent>
                                <div className="card-title" variant="h5" component="div">
                                Integrações
                                </div>
                                <div className="card-content" variant="body1">
                                Adicione os seus pedidos automaticamente
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <button className="card-button" size="small">Saiba mais</button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <div
                    style={{
                        display: isLargeScreen ? 'none' : 'flex',
                        justifyContent: 'center',
                    }}
                    >
                    <button className = "left-button" onClick={prevItem} sx={{ marginRight: 2 }}>
                        <ArrowBackIcon color="white" />
                    </button>

                    <Card className="card-homepage" sx={{ width: "290px", textAlign: "center"}}>
                            <CardContent>
                                <div className="card-title-small" variant="h5" component="div">
                                {carouselItems[currentIndex].icon}
                                {carouselItems[currentIndex].title}
                                </div>
                                <div className="card-content-small" variant="body1">
                                {carouselItems[currentIndex].content}
                                </div>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <button className="card-button-small" size="small">Saiba mais</button>
                            </CardActions>
                        </Card>

                    <button className = "right-button" onClick={nextItem} sx={{ marginLeft: 2 }}>
                        <ArrowForwardIcon color="white" />
                    </button>
                    </div>
            </div>
            <div>
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={6} className="primary-section-3">
            <div className="titulo-3-section" variant="h2" gutterBottom>
                A Motum vai até você buscar seus pacotes!
                <div className="subtitulo-3-section" variant="h5" gutterBottom>
                Serviço de envio simples, rápido, seguro, sem custo extra e sem mínimo de pacote.
                <br/>
                Encontramos o entregador mais próximo do seu endereço para retirar o pacote e fazer a entrega. Simples, Rápido e Seguro!

                </div>
            </div>
            
            </Grid>
            <Grid size={6} className="second-section-3" sx={{ backgroundImage: `url(${section3})`, backgroundSize: "cover"}}>
            </Grid>

            </Grid>

            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'flex', lg:'none',}}}>
            <Grid size={12} className="primary-section-3-lg" sx={{ backgroundImage: `url(${section3})`, backgroundSize: "cover"}}>
            </Grid>
            <Grid size={12} sx={{height:"500px", backgroundColor:"white", display:"flex", alignItems:"center", width: "100%"}}>
            <div className="titulo-3-section-lg" variant="h2" gutterBottom>
                A Motum vai até você buscar seus pacotes!
                <div className="subtitulo-3-section-lg" variant="h5" gutterBottom>
                Serviço de envio simples, rápido, seguro, sem custo extra e sem mínimo de pacote.
                <br/>
                Encontramos o entregador mais próximo do seu endereço para retirar o pacote e fazer a entrega. Simples, Rápido e Seguro!

                </div>
            </div>
            
            </Grid>

            </Grid>
            
            </div>
            
            <div>
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={6} className="primary-section-4">
            <div className="titulo-3-section" variant="h2" gutterBottom>
                Minha Entrega
                <div className="subtitulo-3-section" variant="h5" gutterBottom>
                A Motum entrega e você acompanha o pedido em tempo real pela plataforma. 
                <br/>
                Enviamos SMS para o destinatário assim ele pode ficar sabendo quando o pacote vai chegar.

                </div>
                <button className="send-now-button" sx={{ mb: "12px" }} >Localizar Minha Entrega</button>
            </div>
            
            </Grid>
            <Grid size={6} className="second-section-3" sx={{ backgroundImage: `url(${section4})`, backgroundSize: "cover"}}>
            </Grid>

            </Grid>

            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'flex', lg:'none'}}}>
            <Grid size={12} className="primary-section-4-lg" sx={{ backgroundImage: `url(${section4})`, backgroundSize: "cover", backgroundPosition:"bottom"}}>
            </Grid>
            <Grid size={12} sx={{height:"500px", backgroundColor:"white", display:"flex", alignItems:"center"}}>
            <div className="titulo-3-section-lg" variant="h2" gutterBottom>
                Minha Entrega
                <div className="subtitulo-3-section-lg" variant="h5" gutterBottom sx={{mb: "50px"}}>
                A Motum entrega e você acompanha o pedido em tempo real pela plataforma. 
                <br/>
                Enviamos SMS para o destinatário assim ele pode ficar sabendo quando o pacote vai chegar.

                </div>
                <button className="send-now-button" sx={{ mb: "12px" }} >Localizar Minha Entrega</button>
            </div>
            
            </Grid>

            </Grid>
            
            </div>
            </React.Fragment>
    );
}