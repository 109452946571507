export default function calculateTimeInterval(date) {
    const oldDate = new Date(date);
    const actualDate = new Date();
    const millesecondsDiference = actualDate - oldDate;
    
    const minutes = Math.floor(millesecondsDiference / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 60) {
        return `Há ${minutes} minuto(s)`;
    } else if (hours < 24) {
        return `Há ${hours} hora(s)`;
    } else {
        return `Há ${days} dia(s)`;
    }
}