import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import { faChartSimple, faHeadset, faNetworkWired, faUserCheck, faUserPen, faWallet, faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import AdminService from '../../../services/AdminService';
import PendingUsers from './components/PendingUsers/PendingUsers';
import Integrations from './components/Integrations/Integrations';
import ManageUsers from './components/ManageUsers/ManageUsers';
import ManageWallet from './components/ManageWallet/ManageWallet';
import RegisterSupport from './components/RegisterSupport/RegisterSupport';
import Reports from './components/Reports/Reports';
import Select from 'react-select';
import Swal from 'sweetalert2';
import Logs from './components/Logs/Logs';

export default function Admin(props) {
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [selectedPage, setSelectedPage] = useState("MENU");
    const adminService = useMemo(() => new AdminService(), []);

    async function loadUsers() {
        if (loadingUsers) {
            return;
        }
        setLoadingUsers(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const response = await adminService.getUsers();
            setUsers(response);
            props.setSpinner(false)
        }, 2000);
    }

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleBackMenu() {
        setSelectedPage("MENU");
    }

    function handleChangeUser(userOption) {
        props.setSpinner(true);
        setSelectedUser(users[users.findIndex(user => user._id === userOption.value)]);
        setTimeout(() => {
            props.setSpinner(false);
        }, 2000)
    }

    function handlePage(page) {
        if((page === "MANAGE_USERS" || page === "MANAGE_WALLET" || page === "REGISTER_SUPPORT") && !selectedUser) {
            Swal.fire({
                icon: 'error',
                title: 'Selecione um usuário',
                showConfirmButton: false,
                timer: 2000
            });
            return;
        }
        setSelectedPage(page)
    }

    const usersOptions = users.map(({ _id, name }) => ({ value: _id, label: name }));

    return (
        <div className="content-container admin-container">
            {selectedPage === "MENU" && (<div className='page-title'>Suporte ao usuário</div>)}
            {selectedPage === "MENU" && (
                <div className="select-user-container">
                    <Select
                        options={usersOptions}
                        onChange={handleChangeUser}
                        value={selectedUser ? usersOptions.find((user) => user.value === selectedUser._id) : null}
                        placeholder="Selecione um usuário"
                        className="select-user"
                    />
                </div>
            )}
            {selectedPage === "APROVE_USERS" && (<PendingUsers handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />)}
            {selectedPage === "INTEGRATIONS" && (<Integrations handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />)}
            {selectedPage === "MANAGE_USERS" && (<ManageUsers selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />)}
            {selectedPage === "MANAGE_WALLET" && (<ManageWallet selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />)}
            {selectedPage === "REGISTER_SUPPORT" && (<RegisterSupport selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />)}
            {selectedPage === "REPORTS" && (<Reports handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />)}
            {selectedPage === "LOGS" && (<Logs handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />)}
            {selectedPage === "MENU" && (
                <div className="menu">
                    <div className="support-actions-container">
                        <div className="action-box" onClick={() => handlePage("APROVE_USERS")}>
                            <FontAwesomeIcon className="action-icon" icon={faUserCheck} />
                            <div className="action-label">Aprovar usuários</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("INTEGRATIONS")}>
                            <FontAwesomeIcon className="action-icon" icon={faNetworkWired} />
                            <div className="action-label">Integrações</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("MANAGE_USERS")}>
                            <FontAwesomeIcon className="action-icon" icon={faUserPen} />
                            <div className="action-label">Alterar cadastro</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("MANAGE_WALLET")}>
                            <FontAwesomeIcon className="action-icon" icon={faWallet} />
                            <div className="action-label">Carteiras</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("REGISTER_SUPPORT")}>
                            <FontAwesomeIcon className="action-icon" icon={faHeadset} />
                            <div className="action-label">Cadastrar Suporte</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("REPORTS")}>
                            <FontAwesomeIcon className="action-icon" icon={faChartSimple} />
                            <div className="action-label">Relatórios</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("LOGS")}>
                            <FontAwesomeIcon className="action-icon" icon={faWaveSquare} />
                            <div className="action-label">Logs do Sistema</div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}