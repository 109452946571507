const anotaAiCancellationReasons = [
    {
        cancelCodeId: 1,
        description: "Cliente não atendeu o telefone"
    },
    {
        cancelCodeId: 2,
        description: "Endereço incorreto ou incompleto"
    },
    {
        cancelCodeId: 3,
        description: "Cliente solicitou cancelamento"
    },
    {
        cancelCodeId: 4,
        description: "Pedido duplicado"
    },
    {
        cancelCodeId: 5,
        description: "Problemas no pagamento"
    },
    {
        cancelCodeId: 6,
        description: "Produto fora de estoque"
    },
    {
        cancelCodeId: 7,
        description: "Tempo de entrega muito longo"
    },
    {
        cancelCodeId: 8,
        description: "Erro no pedido (produto ou quantidade incorreta)"
    },
    {
        cancelCodeId: 9,
        description: "Problemas internos do estabelecimento"
    },
    {
        cancelCodeId: 10,
        description: "Motivos de segurança (cliente suspeito ou problema no local)"
    }
];

export default anotaAiCancellationReasons;