import React, { useContext, useEffect, useState } from 'react';
import AddressForm from '../../layout/Address/AddressForm';
import { Navigate } from 'react-router';
import PaymentForm from '../../layout/Payment/PaymentForm';
import PersonalForm from '../../layout/Personal/PersonalForm';
import OrderService from '../../../services/OrderService';
import Order from '../../../model/Order';
import Requester from '../../../model/Requester';
import OrderDetails from '../../../model/OrderDetails';
import Conversor from '../../../helper/Conversor';
import { UserContext } from '../../context/UserContext';
import { useSocket } from '../../context/SocketContext';
import Notification from '../../components/Notification/Notification';
import Swal from 'sweetalert2';
import Utils from '../../../helper/Utils';
import './style.scss';

const XpressRequest = (props) => {
  const { user, selectedStore } = useContext(UserContext);
  const [address, setAddress] = useState();
  const [originAddress, setOriginAddress] = useState(() => {
    return selectedStore ? selectedStore.address : ''
  });
  const [originMarket, setOriginMarket] = useState(() => {
    return selectedStore ?
      {
        lat: selectedStore.address.location.coordinates[0],
        lng: selectedStore.address.location.coordinates[1]
      }
      : { lat: '', lng: '' }
  });
  // eslint-disable-next-line
  const [changeMarket, setChangeMarket] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [shipping, setShipping] = useState('');
  const [shippingRate, setShippingRate] = useState(0.0);
  const [redirect, setRedirect] = useState(false);
  const [coordenadas, setCoordenadas] = useState({});
  const [distance, setDistance] = useState();
  const [time, setTime] = useState(0);
  // eslint-disable-next-line
  const [notificationData, setNotificationData] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const socket = useSocket();

  useEffect(() => {
    console.log(distance);
    console.log(time)
  }, [distance, time])

  useEffect(() => {
    setOriginAddress(selectedStore.address);
    setOriginMarket({
      lat: selectedStore.address.location.coordinates[0],
      lng: selectedStore.address.location.coordinates[1]
    });
  }, [selectedStore]);

  useEffect(() => {
    document.title = `Entrega Xpress | Motum`;
  });

  function renameKeys(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => renameKeys(item));
    }

    const newObj = {};
    for (let key in obj) {
      let newKey = key === '_id' ? 'id' : key;
      newObj[newKey] = renameKeys(obj[key]);
    }
    return newObj;
  }

  const createRequesterFromForm = (address, {
    shipping,
    name,
    phone,
    message,
    price,
    payment,
    change,
  }) => {
    return new Requester(
      {
        cep: Conversor.converterCep(address._cep),
        city: address._city,
        neighborhood: address._neighborhood,
        street: address._street,
        complement: address._complement,
        state: address._state,
        number: address._number,
      },
      name.value,
      Conversor.converterTelefone(phone.value),
      coordenadas.lat,
      coordenadas.lng,
    );
  };

  const createOrderDetailsFromForm = ({
    shipping,
    name,
    phone,
    message,
    price,
    payment,
    change,
  }) => {
    return new OrderDetails(
      Conversor.converterReal(price.value),
      Conversor.converterReal(shipping.value),
      payment.value,
      null,
      Conversor.converterReal(change.value),
      message.value,
      distance
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    props.setSpinner(true);
    setTimeout(async () => {
      const requester = createRequesterFromForm(address, event.target.elements);
      const orderDetails = createOrderDetailsFromForm(event.target.elements);
      const store = renameKeys(selectedStore);
      const order = new Order(requester, orderDetails, store);
      const orderService = new OrderService();
      try {
        const response = await orderService.enviarOrder(order);
        props.setSpinner(false);
        setAddress('');
        setShipping('');
        setShippingRate('');
        event.target.reset();
        if (response.ok) {
          setRedirect(true);
        }
      } catch (error) {
        props.setSpinner(false);
        Swal.fire({
          title: 'Ocorreu um erro!',
          text: error,
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
          didClose: () => {
            setRedirect(false);
          }
        });
      }
    }, 2000);
  };

  const changeShowOthers = (value) => {
    setShowOthers(value);
  };

  const changeAddress = (value) => {
    setAddress(value);
  };

  const changeShipping = (value) => {
    setShipping(value);
  };

  const [orderQueue, setOrderQueue] = useState([]);
  const [processing, setProcessing] = useState(false);

  const schedulePushNotification = (data) => {
    setNotificationData(data);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
      setOrderQueue((prevQueue) => prevQueue.slice(1));
    }, 5000);
    setProcessing(false);
  }

  useEffect(() => {
    setOriginMarket({
      lat: selectedStore.address.location.coordinates[0],
      lng: selectedStore.address.location.coordinates[1]
    });
  }, [selectedStore])

  useEffect(() => {
    console.log(address);
  }, [address])

  useEffect(() => {
    const handleUpdateOrder = (data) => {
      setOrderQueue((prevQueue) => [...prevQueue, data]);
    }

    socket.on('DELIVERED_ORDER', handleUpdateOrder);
    socket.on('WITHDRAWN_ORDER', handleUpdateOrder);
    socket.on('ARRIVED_ORDER', handleUpdateOrder);
    socket.on('ACCEPT_ORDER', handleUpdateOrder);

    return () => {
      socket.off('DELIVERED_ORDER', handleUpdateOrder);
      socket.off('WITHDRAWN_ORDER', handleUpdateOrder);
      socket.off('ARRIVED_ORDER', handleUpdateOrder);
      socket.off('ACCEPT_ORDER', handleUpdateOrder);
      socket.off('ACCEPT_ORDER', handleUpdateOrder);
    };
    //eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    // Process the order queue when it's not empty and not already being processed
    if (orderQueue.length > 0 && !processing && !showNotification) {
      // Set processing to true to prevent simultaneous processing
      setProcessing(true);

      // Get the first order in the queue
      const orderToProcess = orderQueue[0];

      // Execute your function for the order (e.g., schedulePushNotification)
      schedulePushNotification(orderToProcess);
    }
  }, [orderQueue, processing, showNotification]);

  if (redirect) {
    Swal.fire({
      title: 'Solicitação bem-sucedida!',
      text: 'Tudo certo! O cliente receberá seu pedido em breve.',
      icon: 'success',
      timer: 3000,
      showConfirmButton: false,
      didClose: () => {
        setRedirect(false);
      }
    });
  }

  if (!user) {
    return <Navigate to={'/login'} />;
  }

  return (
    <React.Fragment>
      <div className="content-container">
        <form className="form-request" onSubmit={onSubmit} data-form>
          <AddressForm
            changeShowOthers={changeShowOthers}
            showOthers={showOthers}
            changeAddress={changeAddress}
            address={address}
            setAddress={setAddress}
            changeShipping={changeShipping}
            shipping={shipping}
            setCoordenadas={setCoordenadas}
            distance={distance}
            setDistance={setDistance}
            time={time}
            setTime={setTime}
            showTotalShipping={false}
            setSpinner={props.setSpinner}
            setOriginAddress={setOriginAddress}
            originAddress={originAddress}
            originMarket={originMarket}
            setOriginMarket={setOriginMarket}
            changeMarket={changeMarket}
          />

          {showOthers || Utils.isDesktop() ? (
            <div className="resume">
              <PersonalForm />
              <PaymentForm shippingRate={shippingRate} />
              <button className="submit-request-form" data-button-submit style={{ margin: 0 }}>
                Solicitar entrega
              </button>
            </div>
          ) : (
            <></>
          )}
        </form>
        {showNotification ? (<Notification notificationData={notificationData} />) : ("")}
      </div>
    </React.Fragment>
  );
};

export default XpressRequest;
