import HttpService from "./HttpService.js";

export default class OrderGroupingService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/ordergrouping`;
    }

    async createOrderGrouping(store, orders) {
        const response = await this._http.post(this._urlBase+`/`, {
            "store": store,
            "orders": orders
        });
        return response;
    }

    async setPendingGroup(groupingCode, driverId) {
        const response = await this._http.put(this._urlBase+`/cancel/set_pending_group`, {
            "groupingCode": groupingCode,
            "driverId": driverId
        });
        return response;
    }

    async ungroupOrders(groupingCode) {
        const response = await this._http.delete(this._urlBase+`/cancel/ungroup_orders`, {
            "groupingCode": groupingCode
        });
        return response;
    }
}
