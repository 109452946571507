import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

const LoadingSpinner = (props) => {
    return (
        <div style={props.visible ? {display: 'block', position: 'absolute'} : {display: 'none', position: 'absolute'}}>
            <div className="loading-container">
                <ThreeCircles
                    visible={props.visible}
                    height="80"
                    width="80"
                    color="#fff"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="loading-spinner"
                />
                <p className="loading-spinner-text">{props.text}</p>
            </div>
        </div>
    );
};

export default LoadingSpinner;
