import HttpService from "./HttpService.js";

export default class OrderService {
    constructor() {
        this._http = new HttpService();
        // this._urlBase = "http://motum-test.herokuapp.com/orders";
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/orders`;
        this._urlDeliveryMan = `${process.env.REACT_APP_DELIVERIES_API}/driver`;
        this._urlAnotaAi = `${process.env.REACT_APP_DELIVERIES_API}/anotaai`;
    }

    async enviarOrder(order) {
        const orderResponse = await this._http.post(this._urlBase, order);
        return orderResponse;
    }

    async setPendingOrder(order_id, deliveryman_id) {
        const orderResponse = await this._http.put(this._urlBase+"/"+order_id, {"order": {"orderDetails.status": "pending"}});
        if(orderResponse) {
            const deliveryManResponse = await this._http.put(this._urlDeliveryMan+"/cancel_order",{"order_id": order_id, "deliveryman_id": deliveryman_id});
            if(deliveryManResponse) {
                return true;
            }
        }else {
            return false;
        }
        return orderResponse;
    }

    async cancelOrder(order_id) {
        const orderResponse = await this._http.put(this._urlBase+"/cancel_order",{"order_id": order_id});
        return orderResponse;
    }

    async getOrders(page, store_id) {
        // const orderResponse = await this._http.get(this._urlBase+"/get_ifood_orders");
        const orderResponse = await this._http.get(this._urlBase+"/"+page+"/"+store_id);
        return orderResponse;
    }

    async getOrderByStatus(page, store_id, status, initialDate, endDate) {
        let url = `${this._urlBase}/status/${page}/${store_id}?status=${status}`;
        
        if (initialDate && endDate) {
            url += `&initialDate=${initialDate}&endDate=${endDate}`;
        }
        const orderResponse = await this._http.get(url);
        
        return orderResponse;
    }    

    async deleteOrder(order_id) {
        const orderResponse = await this._http.delete(this._urlBase+"/"+order_id);
        return orderResponse;
    }

    async getDeliveryMans() {
        const response = await this._http.get(this._urlDeliveryMan+"/");
        return response;
    }

    async getDriverReport(status, deliveryManId, initialDate, endDate) {
        let url = `${this._urlBase}/drivereport/?status=${status}&deliveryManId=${deliveryManId}`;
        if (initialDate && endDate) {
            url += `&initialDate=${initialDate}&endDate=${endDate}`;
        }
        const response = await this._http.get(url);
        return response;
    }

    async showDeliveryMan(deliveryman_id) {
        const storeResponse = await this._http.get(this._urlDeliveryMan+`/id/${deliveryman_id}`);
        return storeResponse;
    }

    async getIfoodOrders(status) {
        const response = await this._http.get(this._urlBase+`/get_ifood_orders`);
        return response;
    }

    async confirmOrder(orderId, storeId, isIFood = false, isAnotaAi = false) {
        if(isIFood) {
            const response = await this._http.post(this._urlBase+`/confirm_ifood_order/${orderId}/${storeId}`);
            return response;
        }
        else if (isAnotaAi) {
            const response = await this._http.post(this._urlAnotaAi+`/accept/${orderId}/${storeId}`);
            return response;
        }
    }

    async dispatchOrder(orderId, storeId) {
        const response = await this._http.post(this._urlBase+`/dispatch_ifood_order/${orderId}/${storeId}`);
        return response;
    }

    async readyToPickupOrder(orderId, storeId) {
        const response = await this._http.post(this._urlBase+`/ready_to_pickup_ifood_order/${orderId}/${storeId}`);
        return response;
    }

    async getCancellationReasons(orderId, storeId) {
        const response = await this._http.get(this._urlBase+`/get_ifood_cancellation_reasons/${orderId}/${storeId}`);
        return response;
    }

    async requestCancellation(orderId, storeId, cancellationCode, reason, isIFood = true) {
        if(isIFood) {
            const response = await this._http.post(this._urlBase+`/request_ifood_cancellation/${orderId}/${storeId}`, {
                "cancellationCode": cancellationCode,
                "reason": reason
            });
            return response;
        } else {
            const response = await this._http.post(this._urlAnotaAi+`/cancel/${orderId}/${storeId}`, {
                "justification": reason
            });
            return response;
        }
    }

    async getAllOrdersByStore(storeId, filters) {
        const queryParams = new URLSearchParams();
        if (filters.startDate) queryParams.append('startDate', filters.startDate);
        if (filters.endDate) queryParams.append('endDate', filters.endDate);
        if (filters.deliveryCode) queryParams.append('deliveryCode', filters.deliveryCode);
        if (filters.customerName) queryParams.append('customerName', filters.customerName);
        if (filters.page) queryParams.append('page', filters.page.toString());
        if (filters.status) queryParams.append('status', filters.status);

        const url = `${this._urlBase}/${storeId}?${queryParams.toString()}`;
        const response = await this._http.get(url);
        return response;
    }

    async getCountStatusByStore(storeId) {
        const response = await this._http.get(this._urlBase+`/count_by_status/${storeId}`);
        return response;
    }
    
}
