import React, { useState, useEffect, useMemo } from "react";
import IfoodService from "../../../../../services/IfoodService";
import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Conversor from "../../../../../helper/Conversor";
import Swal from "sweetalert2";

export default function Integrations(props) {
    const [integrations, setIntegrations] = useState([]);
    const [loadingIntegrations, setLoadingIntegrations] = useState(false);

    const ifoodService = useMemo(() => new IfoodService(), []);

    async function aproveIntegration(integrationId) {
        try {
            props.setSpinner(true);
            const response = await ifoodService.aproveIfoodIntegration(integrationId);
            setLoadingIntegrations(false);
            setTimeout(() => {
                props.setSpinner(false);
                if (response) {
                    Swal.fire({
                        title: 'Integração aprovada com sucesso!',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                    });
                } else {
                    Swal.fire({
                        title: 'Ocorreu um erro ao aprovar a integração!',
                        icon: 'error',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                }
            }, 2000);
        } catch (error) {
            console.error(error);
        }
    }

    async function loadIntegrations() {
        if (loadingIntegrations) {
            return;
        }

        setLoadingIntegrations(true);

        try {
            const response = await ifoodService.getIfoodIntegrations();
            setIntegrations(response);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!loadingIntegrations) {
            loadIntegrations();
        }
        // eslint-disable-next-line
    }, [loadingIntegrations]);

    return (
        <React.Fragment>
            <div className="content-container integrations-container">
                <h1 className="integrations-title">Integrações Pendentes</h1>
                <table className="integrations-table">
                    <thead>
                        <tr>
                            <th>ID IFood</th>
                            <th>CNPJ</th>
                            <th>Nome da Loja</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {integrations.length > 0 && integrations.map(integration => (
                            integration.ifoodMerchantId.map(ifoodId => (
                                <tr key={`${integration._id}-${ifoodId}`}>
                                    <td className="integration-id"><span>{ifoodId}</span></td>
                                    <td className="cpnj-td">{Conversor.converterCNPJ(integration.cnpj)}</td>
                                    <td className="store-name-td">{integration.companyName}</td>
                                    <td className="action-button">
                                        <button onClick={() => aproveIntegration(integration._id)} type="button">
                                            <FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, height: 14 }} />
                                            Marcar como Integrado
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
                <div className="back-action">
                    <button className="back-btn" onClick={props.handleBackMenu}>Voltar</button>
                </div>
            </div>
        </React.Fragment>
    );
};