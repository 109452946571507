import { useMemo, useState } from "react";
import AdminService from "../../../../../services/AdminService";
import InputMask from 'react-input-mask';
import './style.scss';
import Swal from "sweetalert2";

export default function ManageUsers(props) {
    const [user, setUser] = useState(props.selectedUser);

    const adminService = useMemo(() => new AdminService(), []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name.includes('address.')) {
            const addressField = name.split('.')[1];
            setUser(prevUser => ({
                ...prevUser,
                address: {
                    ...prevUser.address,
                    [addressField]: value
                }
            }));
        } else if (name.includes('metadata.cnh')) {
            const cnhField = name.split('.')[1];
            setUser(prevUser => ({
                ...prevUser,
                metadata: {
                    ...prevUser.metadata,
                    [cnhField]: value
                }
            }));
        } else if (name.includes('metadata.vehicle.')) {
            const vehicleField = name.split('.')[2];
            setUser(prevUser => ({
                ...prevUser,
                metadata: {
                    ...prevUser.metadata,
                    vehicle: {
                        ...prevUser.metadata.vehicle,
                        [vehicleField]: value
                    }
                }
            }));
        
        } else {
            setUser(prevUser => ({
                ...prevUser,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            props.setSpinner(true);
            await adminService.updateUser(user._id, user);
            props.setSpinner(false);
            Swal.fire({
                icon: 'success',
                title: 'Dados atualizado com sucesso!',
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    return (
        <div className="content-container manage-users-container">
            <div className="title">Alterar Cadastro</div>
            <div className="sub-title">Selecione um usuário</div>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label>Nome:</label>
                    <input
                        type="text"
                        name="name"
                        value={user.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>CPF:</label>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu CPF'
                        type="text"
                        name="cpf"
                        mask="999.999.999-99" // Máscara para CPF
                        value={user.cpf}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Telefone:</label>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu telefone'
                        type="text"
                        name="cellNumber"
                        mask="(99) 99999-9999" // Máscara para Telefone
                        value={user.cellNumber}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>CEP:</label>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu CEP'
                        type="text"
                        name="address.cep"
                        mask="99999-999" // Máscara para CEP
                        value={user.address.cep}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Cidade:</label>
                    <input
                        type="text"
                        name="address.city"
                        value={user.address.city}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Rua:</label>
                    <input
                        type="text"
                        name="address.street"
                        value={user.address.street}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Número:</label>
                    <input
                        type="number"
                        name="address.address_number"
                        value={user.address.address_number}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Bairro:</label>
                    <input
                        type="text"
                        name="address.neighborhood"
                        value={user.address.neighborhood}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Complemento:</label>
                    <input
                        type="text"
                        name="address.complement"
                        value={user.address.complement}
                        onChange={handleChange}
                    />
                </div>

                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>CNH:</label>
                        <input
                            type="text"
                            name="metadata.cnh"
                            value={user.metadata.cnh}
                            onChange={handleChange}
                        />
                    </div>
                )}

                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>Modelo do Veículo</label>
                        <input
                            type="text"
                            name="metadata.vehicle.model"
                            value={user.metadata.vehicle.model}
                            onChange={handleChange}
                        />
                    </div>
                )}

                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>Placa do Veículo</label>
                        <input
                            type="text"
                            name="metadata.vehicle.vehiclePlate"
                            value={user.metadata.vehicle.vehiclePlate}
                            onChange={handleChange}
                        />
                    </div>
                )}

                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>Tipo de Veículo:</label>
                        <select name="metadata.vehicle.type" id="" value={user.metadata.vehicle.type} onChange={handleChange}>
                            <option value="motorcycle">Moto</option>
                            <option value="car">Carro</option>
                        </select>
                    </div>
                )}


                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>Cor do Veículo</label>
                        <input
                            type="text"
                            name="metadata.vehicle.color"
                            value={user.metadata.vehicle.color}
                            onChange={handleChange}
                        />
                    </div>
                )}

                {user._id ? (
                    <div className="form-control">
                        <button type="submit">Salvar Alterações</button>
                    </div>
                ) : (null)}
            </form>
            <div className="back-action">
                <button className="back-btn" onClick={props.handleBackMenu}>Voltar</button>
            </div>
        </div>
    );
}