import React, { useState, useCallback } from 'react';
import { GoogleMap, Marker, LoadScript, Autocomplete } from '@react-google-maps/api';
import Field from '../Fieldset/Fieldset';
import packet from './../../../assets/img/maps/entrega-map.svg';
import departure from './../../../assets/img/maps/shop_location_pin.svg';
import Modal from '../Modal/Modal';
import './style.scss';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const libraries = ["places"];

const createAddress = (addressArray) => {
  try {
    const address = {
      cep: addressArray.find(component => component.types.includes('postal_code'))?.long_name || '',
      city: addressArray.find(component => component.types.includes('administrative_area_level_2'))?.long_name || '',
      neighborhood: addressArray.find(component => component.types.includes('sublocality'))?.long_name || '',
      street: addressArray.find(component => component.types.includes('route'))?.long_name || '',
      state: addressArray.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '',
      number: addressArray.find(component => component.types.includes('street_number'))?.long_name || '',
      country: addressArray.find(component => component.types.includes('country'))?.long_name || '',
    };
    return address;
  } catch (error) {
    console.log(error);
    return '';
  }
};

const MapMotum = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [endereco, setEndereco] = useState('');
  const [autocomplete, setAutocomplete] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const storeMarket = props.originMarket;

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const options = {
    gestureHandling: 'greedy',
    clickableIcons: false,
    streetViewControl: false,
    fullscreenControlOptions: false,
    mapTypeControl: false,
    styles: [
      { elementType: 'labels', featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
    ],
  };

  const onMapClick = useCallback(
    async (...args) => {
      const mapCoordinates = {
        lat: args[0].latLng.lat(),
        lng: args[0].latLng.lng(),
      };

      const resultado = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${mapCoordinates.lat},${mapCoordinates.lng}&key=${process.env.REACT_APP_MAPS_APP_KEY}`
      );
      const enderecoData = await resultado.json();

      if (enderecoData.results && enderecoData.results[0]) {
        const clickedAddress = createAddress(enderecoData.results[0].address_components);

        if (autocomplete) {
          const formattedAddress = [
            clickedAddress.street,
            clickedAddress.number,
            clickedAddress.neighborhood,
            clickedAddress.city,
            clickedAddress.state,
            clickedAddress.country,
          ].filter(Boolean).join(', ');

          const inputField = document.querySelector('.pac-target-input');

          if (inputField) {
            inputField.value = formattedAddress;

            const event = new Event('change', { bubbles: true });
            inputField.dispatchEvent(event);
          }
        }

        props.setMarket(mapCoordinates);
        props.setExibir(false);
        setEndereco(clickedAddress);
        props.handler(clickedAddress);
        props.calculateShipping(mapCoordinates);
      }
    },
    [setEndereco, props, autocomplete]
  );

  const handleOriginAddress = async () => {
    if (autocomplete && autocomplete.getPlace) {
      const place = autocomplete.getPlace();

      // Check if 'place' object and 'geometry' object are available
      if (place && place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const long = place.geometry.location.lng();
        const newLat = {
          lat: lat,
          lng: long,
        };

        const resultado = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${newLat.lat},${newLat.lng}&key=${process.env.REACT_APP_MAPS_APP_KEY}`
        );
        const endereco1 = await resultado.json();
        const newAddress = createAddress(endereco1.results[0].address_components);

        props.setOriginAddress({
          ...newAddress, "location": {
            "type": "Point",
            "coordinates": [newLat.lng, newLat.lat]
          }
        });
        props.handleOriginMarket(newLat);
        if (!props.exibir) {
          props.calculateShipping(newLat);
        }
      }
    }
  }


  const handlePlacesChanged = async () => {
    if (autocomplete && autocomplete.getPlace) {
      const place = autocomplete.getPlace();

      // Check if 'place' object and 'geometry' object are available
      if (place && place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const long = place.geometry.location.lng();
        const newLat = {
          lat: lat,
          lng: long,
        };

        const resultado = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${newLat.lat},${newLat.lng}&key=${process.env.REACT_APP_MAPS_APP_KEY}`
        );
        const endereco1 = await resultado.json();
        const newAddress = createAddress(endereco1.results[0].address_components);

        props.setMarket(newLat);
        props.setExibir(false);
        props.handler(newAddress);
        props.calculateShipping(newLat);
      }
    }
  };


  const onLoadAutocomplete = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  return (
    <div className="google-map-container">
      <LoadScript
        id="script-loader"
        googleMapsApiKey={process.env.REACT_APP_MAPS_APP_KEY}
        libraries={libraries}
      >
        <div className="search-box">
          <Autocomplete
            onLoad={(autocompleteInstance) => onLoadAutocomplete(autocompleteInstance)}
            onPlaceChanged={handlePlacesChanged}
            bounds={{
              east: -37.0353,
              north: -10.8536,
              south: -10.9943,
              west: -37.1582
            }}
          >
            <Field
              content="Endereço de Entrega"
              labelAnimation="animation--opacity-4"
              type="text"
            />
          </Autocomplete>
        </div>
        <div className="map">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={props.market ? props.market : storeMarket}
            zoom={props.zoom}
            onUnmount={onUnmount}
            onClick={onMapClick}
            options={options}
          >
            {!props.exibir && props.address !== "" ? (
              <>
                <Marker
                  position={storeMarket}  // Coordenadas do marcador A (origem)
                  icon={departure}
                  clickable={false}
                  label={`B`} />
                <Marker
                  position={props.market} // Coordenadas do marcador B (destino)
                  icon={packet}
                  clickable={false}
                  label={`${(props.time / 100).toFixed(0)} min - ${(props.distance / 1000).toFixed(1)} km`}
                />
                {/* {!directionsRequested && (
                  <DirectionsService
                    options={{
                      destination: props.market, // Coordenadas do destino
                      origin: storeMarket, // Coordenadas de origem
                      travelMode: 'DRIVING', // Modo de transporte (DRIVING, WALKING, BICYCLING)
                    }}
                    callback={directionsCallback}
                  />
                )}
                {response && <DirectionsRenderer directions={response} options={{ suppressMarkers: true, polylineOptions: { strokeColor: 'black' } }} />} */}
              </>) : (
              <Marker
                position={storeMarket}
                icon={departure}
                clickable={false}
              />
            )}
          </GoogleMap>
        </div>
        <Modal
          isOpen={modalVisible}
          onClose={() => { setModalVisible(false) }}
          input={
            <Autocomplete
              onLoad={(autocompleteInstance) => onLoadAutocomplete(autocompleteInstance)}
              onPlaceChanged={handleOriginAddress}
              bounds={{
                east: -37.0353,   // Longitude máxima da sua área
                north: -10.8536, // Latitude máxima da sua área
                south: -10.9943, // Latitude mínima da sua área
                west: -37.1582   // Longitude mínima da sua área}
              }}
            >
              <Field
                content="Endereço de Origem"
                labelAnimation="animation--opacity-4"
                type="text"
              />
            </Autocomplete>
          } />
      </LoadScript>
    </div>
  );
};

export default React.memo(MapMotum);
